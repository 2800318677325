import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Select, Tag } from "antd";
import { connect } from "react-redux";
import parse from "html-react-parser";
import loadable from "@loadable/component";
import isEmpty from "lodash/isEmpty";
import { get } from "lodash";

// components
import ShowMore from "../shared/ShowMore";
import ItemBlock from "../shared/ItemBlock";
import ItemBlockSkeleton from "../shared/ItemBlockSkeleton";
import Pagination from "../shared/Pagination";
import CategoryBreadcrumbs from "./CategoryBreadcrumbs";
import DropDown from "../shared/DropDown";
import SaveSearchButton from "../shared/SaveSearchButton";
import RedSpinner from "../shared/RedSpinner";
import DataLayer from "../shared/DataLayer";
import CategorySelect from "./components/CategorySelect";
import CombinedSortIcon from "../Icons/CombinedSortIcon";
import CategoryPageSearch from "./components/CategoryPageSearch";
import DirectoryAdsBanner from "../shared/DirectoryAdsBanner";
const SubscribeNewsletter = loadable(() => import("../shared/SubscribeNewsletter"));
const SidebarItems = loadable(() => import("../shared/SidebarItems"));
const CategorySidebarItems = loadable(() => import("./CategorySidebarItems"));
const BrowseToNavigationDrawer = loadable(() => import("./components/BrowseToNavigationDrawer"));
const SortByDrawer = loadable(() => import("./components/SortByDrawer"));
const NotFound = loadable(() => import("../NotFound/NotFound"));
const RouteWithStatus = loadable(() => import("../router/components/RouteWithStatus"));

// redux
import { openMobileDropdown, setMobileDropdownKey } from "../../redux/general/generalActions";
import { getTopPeriods } from "../../redux/periods/periodsSelector";
import { getFavoriteItemsIdsRequest } from "../../redux/profiles/profilesActions";

// hooks
import useCategoryPageData from "../../hooks/useCategoryPageData";
import useQueryParams from "../../hooks/useQueryParams";
import useServerSafeSWR from "../../hooks/useServerSafeSWR";
import useDimensions from "../../hooks/useDimensions";

// helpers
import {
  formatCategoryItems,
  returnCategoryPageDataLayer,
  returnSubcategoryPageDataLayer,
  returnSimilarCategoryPageDataLayer,
  returnCategorySearchDataLayer,
  returnSubscribeToCategoryDataLayer,
  transformComponentForReactHelmetMarkupSchema
} from "../../helpers";

// constants
import { DEFAULT_PAGE_SIZE_OPTIONS } from "../../constants";

// assets
import "react-quill/dist/quill.snow.css";
import "../../assets/styles/components/categoryPage/categoryPage.scss";
import "../../assets/styles/components/categoryPage/categoryPageBottomText.scss";

const CATEGORY_SORTING_OPTIONS = [
  { value: "-date", label: "Newest" },
  { value: "date", label: "Oldest" },
  { label: "Price High/Low", value: "make_this_price_poa,-price" },
  { label: "Price Low/High", value: "make_this_price_poa,price" },
  { label: "Sold", value: "is_sold" }
];

const NO_INDEX = "noindex, follow";
const SIMILAR_SEARCHES = "Similar Searches";

function modifyChildrenUrls(array, modifier) {
  return array.map(item => {
    const itemUrl = item.isLinked ? `${item.url}` : `${modifier}/${item.url}`;

    return {
      ...item,
      url: itemUrl
    };
  });
}

function childrenCategoriesForMobileDropDown(array, modifier) {
  return array.map(item => {
    const itemUrl = item.isLinked ? `${item.url}` : `${modifier}/${item.url}`;

    return {
      label: item.title,
      value: itemUrl,
      itemsCount: item.itemsCount,
    };
  });
}

export const CategoryPage = ({
  match,
  location,
  history,
  periods,
  allCategories,
  allPeriods,
  isAuthenticated,
  getFavoriteItemsIds,
}) => {
  const { categoryUrl, seoCategory1, seoCategory2 } = match.params;
  const [{ page = 1, perPage = 40, filter = "-date", ...otherQueries }, setQueryParams] = useQueryParams();

  const [subCategoryValue, setSubCategoryValue] = useState([]);
  const [isBrowseToDrawerOpened, setIsBrowseToDrawerOpened] = useState(false);
  const [isSortOptionsDrawerOpen, setIsSortOptionsDrawerOpen] = useState(false);
  const [collapsableSidebars, setCollapsableSidebars] = useState(
    { material: true, period: true, origin: true, similarSearches: true }
  );
  const [ref, { width }] = useDimensions({ liveMeasure: true });

  const isAntiquesSoldPage = location.pathname === "/antiques/sold";
  const isAllAntiquesPage = location.pathname === "/antiques";
  const isServer = typeof window === "undefined";

  const isSimilarSearchCategory = location.pathname.slice(1).split("/").length >= 3;
  const isRedirectedFromSearchResults = location.search.includes("search");

  // Get data
  const data = useCategoryPageData();

  const {
    loadingProcess,
    category,
    categoryLoading,
    topCategory,
    items,
    itemsCount,
    itemsCountTotal,
    itemsLoading,
    categoryChildren = [],
    isSeoCategory,
    categoryRelatedPeriods,
    categoryRelatedOrigins,
    categoryRelatedMaterials,
    similarSearches,
  } = data;

  const { data: breadcrumbs, error: breadcrumbsError } = useServerSafeSWR(
    `/categories/all/breadcrumb/?urls=${[categoryUrl, seoCategory1, seoCategory2].filter(u => u).join(",")}`
  );

  const isTopCategory = !isEmpty(topCategory) && !category.parent && !category.isSeoCategory;

  const visibleCategoryChildren = [];
  const hiddenCategoryChildren = [];

  categoryChildren.forEach(item => {
    return item.isHiddenForSidemenu ? hiddenCategoryChildren.push(item) : visibleCategoryChildren.push(item)
  });

  const itemsListRef = useRef(null);
  const subCategoriesItems = visibleCategoryChildren && topCategory ?
    childrenCategoriesForMobileDropDown(visibleCategoryChildren || [], topCategory.url) :
    [];

  const optionsForFilterCategory =
    location.pathname === "/antiques" ?
      allCategories.map(innerCategory => ({
        label: innerCategory.title,
        itemsCount: innerCategory.itemsCount,
        value: innerCategory.url
      }))
      : subCategoriesItems;

  const selectedOrderingOption = CATEGORY_SORTING_OPTIONS.find(option => option.value === filter);
  const selectedSubcategory = optionsForFilterCategory.find(option => option.value === subCategoryValue);

  useEffect(() => {
    if (isAuthenticated) {
      getFavoriteItemsIds();
    }
  }, []);

  useEffect(() => {
    // return to same position when navigating by browser arrows
    if (history.action === "POP") {
      return;
    }

    if (itemsListRef.current && page !== 1) {
      itemsListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      // without timeout it does not scroll top when navigating using browser arrows
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 0);
    }
  }, [items, history]);
  
  // set value of sub categories dropdown
  useEffect(() => {
    const currentSubcategory = subCategoriesItems.find(subcategory => subcategory.value === location.pathname.slice(1));

    if (currentSubcategory) {
      setSubCategoryValue(currentSubcategory.value);
    }
  }, [subCategoriesItems]);

  const handleChangePageSize = value => {
    setQueryParams({ page: null, perPage: value });
  };

  const handleChangeSort = value => {
    setQueryParams({ page: null, filter: value });
  };

  const renderAllAntiquesSidebar = () => {
    if (!location.pathname.includes("/antiques")) {
      return null;
    }

    return (
      <>
        <SidebarItems
          title="Antique Categories"
          items={allCategories}
          linkTo="category"
          firstRow={
            <div className="home-sidebar--row">
              <Link to="/antiques" className="home-sidebar--row--link">
                All Categories
              </Link>
            </div>
          }
        />

        <SidebarItems
          title="Antique Period"
          items={periods}
          linkTo="period"
          lastRow={<Link to="/period" className="home-sidebar--row">View All Periods</Link>}
        />
      </>
    );
  };

  const renderSubcategoriesSidebar = () => (
    <SidebarItems
      title={breadcrumbs ? `Antique ${breadcrumbs[0].title} For Sale` : ""}
      items={breadcrumbs ? modifyChildrenUrls(visibleCategoryChildren, breadcrumbs[0].url) : []}
      linkTo="category"
      selectedItem={seoCategory1 || null}
    />
  );

  const renderFiltersTags = () => {
    if (!otherQueries) {
      return;
    }

    return (
      <div className="category__filters">
        {(!!otherQueries.priceMin || !!otherQueries.priceMax) && (
          <Tag
            className="category__filter-tag category__filter-tag_first"
            closable
            onClose={() => {
              setQueryParams({
                page,
                perPage,
                filter,
                ...otherQueries,
                priceMax: null,
                priceMin: null,
              });
            }}
          >
            <div className="category__filter-tag-content">
              <span className="category__filter-tag-title">Price: </span>
              {otherQueries.priceMin ? `£${otherQueries.priceMin}` : null}
              {otherQueries.priceMax && otherQueries.priceMin ? "-" : null}
              {otherQueries.priceMax ? `£${otherQueries.priceMax}` : null}
            </div>
          </Tag>
        )}
      </div>
    );
  }

  const identifyIsPageIndexable = () => {
    switch (true) {
      case location.pathname === "/antiques":
        return NO_INDEX;
      case location.pathname === "/antiques/sold" && +page > 1:
        return NO_INDEX;
      case +page > 5:
        return NO_INDEX;
      case !!isAntiquesSoldPage:
        return "";
      case !!get(data, "category.noIndex"):
        return NO_INDEX;
      default:
        break;
    }
  };

  const createTagsForCustomScripts = (string, id) => {
    if (!string.trim().startsWith("<script")) {
      string = `<script id=${id} type="application/ld+json">${string}</script>`;
    }

    let components = parse(string);
    if (!Array.isArray(components)) {
      components = [components];
    }

    return components?.map(component => transformComponentForReactHelmetMarkupSchema(component, id));
  };

  const renderHiddenSeoUrls = () => {
    // urls for crawler
    const hiddenSeoUrlsToRender = [];

    // collect urls when sidebars are not expanded
    if (!isEmpty(categoryRelatedMaterials) && !collapsableSidebars.material) {
      hiddenSeoUrlsToRender.push(...categoryRelatedMaterials);
    }
    if (!isEmpty(categoryRelatedPeriods) && !collapsableSidebars.period) {
      hiddenSeoUrlsToRender.push(...categoryRelatedPeriods);
    }
    if (!isEmpty(categoryRelatedOrigins) && !collapsableSidebars.origin) {
      hiddenSeoUrlsToRender.push(...categoryRelatedOrigins);
    }
    if (!isEmpty(similarSearches) && !collapsableSidebars.similarSearches) {
      hiddenSeoUrlsToRender.push(...similarSearches);
    }

    if (!isEmpty(hiddenSeoUrlsToRender)) {
      return (
        <div className="category--hidden-urls">
          {hiddenSeoUrlsToRender.map(item => {
            return (
              <Link key={item.id} to={`/${item.url}`}>
                {item.title}
              </Link>
            )
          })}
        </div>
      )
    }
  };

  const handleTriggerCollapsableSidebar = (sidebarKey, collapseValue) => {
    // control collapsable state of sidebar
    const updatedCollapsableSidebars = { ...collapsableSidebars, [sidebarKey]: collapseValue };
    setCollapsableSidebars(updatedCollapsableSidebars);
  };

  const renderCategorySearchDataLayerScript = () => {
    if (isRedirectedFromSearchResults) {
      return (
        <script>
          {
            returnCategorySearchDataLayer(
              category,
              breadcrumbs,
              location.pathname + location.search,
              isSimilarSearchCategory
            )
          }
        </script>
      )
    }
  };

  // Render
  if (data.shouldRedirectTo) {
    if (data.shouldRedirectTo === "/not-found") {

      return <RouteWithStatus path="*" component={NotFound} status={404} />;
    }

    return <Redirect to={data.shouldRedirectTo} />;
  }

  // Meta description
  const cheapestItem = category.prices && category.prices.cheapestItem;
  const mostExpensiveItem = category.prices && category.prices.mostExpensiveItem;
  const optionalMetaDescription = `${itemsCountTotal} Antique and Vintage ${category.title} For Sale - priced from £${cheapestItem} to £${mostExpensiveItem}`;
  const categoryMetaDescription = category && category.metaDescription
    ? category.metaDescription
    : optionalMetaDescription;
  const seoCategoryMetaDescription = category.shouldUseMeta
    ? category.metaDescription
    : optionalMetaDescription;

  const metaDescription = isSeoCategory ? seoCategoryMetaDescription : categoryMetaDescription;

  // Category banner image
  let categoryBannerImageSrc = `${process.env.REACT_APP_IMAGES_URL}default-category-banner.png`;
  if (category.banner) {
    categoryBannerImageSrc = category.banner.url;
  }
  if (location.pathname === "/antiques") {
    categoryBannerImageSrc = `${process.env.REACT_APP_IMAGES_URL}storefront_images/all-categories-banner.jpeg`;
  }

  // H1 text
  let categoryHeading = "";

  if (location.pathname === "/antiques") {
    categoryHeading = "Recently Added Antiques";
  } else if (category.title === "Jewellery") {
    categoryHeading = "Antique Jewellery & UK Vintage Jewellery For Sale";
  } else if (isAntiquesSoldPage) {
    categoryHeading = "Sold Antiques";
  } else if (category.h1) {
    categoryHeading = category.h1;
  } else {
    categoryHeading = `Antique ${category.title}`;
  }

  const itemsCountTotalText = formatCategoryItems(data.itemsCountTotal, true);
  const categoryHeadingWithItems = location.pathname === "/antiques" || isAntiquesSoldPage
    ? categoryHeading
    : `${categoryHeading} ${itemsCountTotalText}`;

  // Page title
  let pageTitle = "Latest Items from our Online Antiques Store";
  if (category.pageTitle) {
    pageTitle = category.pageTitle;
  }
  if (!category.pageTitle && category.title) {
    pageTitle = `Antique ${category.title} for sale`;
  }
  if (isAntiquesSoldPage) {
    pageTitle = "Sold Antiques";
  }

  return (
    <>
      {isAntiquesSoldPage || isAllAntiquesPage ? <DataLayer pageTitle={pageTitle} /> : null}
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" charSet="utf-8" content={category.title} />
        <meta property="twitter:title" content={category.title} />

        <meta name="description" content={metaDescription} />
        <meta property="og:description" charSet="utf-8" content={metaDescription} />

        <meta property="twitter:description" content={metaDescription} />

        {(category.image && category.image.url) && <meta property="og:image" content={category.image.url} />}
        {(category.image && category.image.url) && <meta property="twitter:image" content={category.image.url} />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={category.title} />

        <meta name="robots" content={identifyIsPageIndexable()} data-id="category" />
        {
          category.faqSchema && typeof window === "undefined"
            ? createTagsForCustomScripts(category.faqSchema, "faqSchema")
            : ""
        }
        {
          category.markupSchema && typeof window === "undefined"
            ? createTagsForCustomScripts(category.markupSchema, "markupSchema")
            : ""
        }

        {`${page}` === "1" ? (
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_URL}/${category.canonical || location.pathname.slice(1)}`}
          />
        ) : (
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_URL}/${category.canonical || location.pathname.slice(1)}?page=${page}`}
          />
        )}
        
        {!isServer && !isEmpty(category) && !category.parent && !category.isSeoCategory &&
          !isRedirectedFromSearchResults && (
          <script>
            {returnCategoryPageDataLayer(category, breadcrumbs)}
          </script>
        )}
        {!isServer && !isEmpty(category) && category.parent && breadcrumbs && !isRedirectedFromSearchResults && (
          <script>
            {returnSubcategoryPageDataLayer(category, breadcrumbs[0])}
          </script>
        )}
        {!isServer && !categoryLoading && (isSimilarSearchCategory || category.isSeoCategory) &&
          breadcrumbs && !isRedirectedFromSearchResults && (
          <script>
            {returnSimilarCategoryPageDataLayer(category, breadcrumbs)}
          </script>
        )}
        {!isEmpty(category) && !isServer && breadcrumbs && renderCategorySearchDataLayerScript()}
      </Helmet>
      <div className="category" ref={ref}>
        <div className="page-container">
          {categoryLoading ? (
            <RedSpinner centered />
          ) : (
            <>

              <div className="category--items-header category--breadcrumbs">
                <CategoryBreadcrumbs
                  currentCategory={{ title: category.title, url: category.url }}
                  breadcrumbs={breadcrumbs}
                  breadcrumbsError={breadcrumbsError}
                />
              </div>
              <Row gutter={[30, 30]}>
                {renderHiddenSeoUrls()}
                {!isEmpty(hiddenCategoryChildren) && (
                  <div className="category--hidden-urls">
                    {breadcrumbs && modifyChildrenUrls(hiddenCategoryChildren, breadcrumbs[0].url).map(item => {

                      return (
                        <Link key={item.id} to={`/${item.url}`}>
                          {item.title}
                        </Link>)
                    })}
                  </div>
                )}

                <Col xl={6} lg={0} xs={0} className="category--sidebars-section">
                  {!loadingProcess && !categoryLoading && (
                    <>
                      {!isEmpty(visibleCategoryChildren) && isTopCategory && renderSubcategoriesSidebar()}

                      {renderAllAntiquesSidebar()}

                      {!isEmpty(categoryRelatedMaterials) && (
                        <CategorySidebarItems
                          isSorted={false}
                          title="Material"
                          items={categoryRelatedMaterials}
                          content="material"
                          isCollapsable
                          isCollapsed={collapsableSidebars.material}
                          handleCollapseItems={handleTriggerCollapsableSidebar}
                        />
                      )}

                      {!isEmpty(categoryRelatedPeriods) && (
                        <CategorySidebarItems
                          isSorted={false}
                          title="Period"
                          items={categoryRelatedPeriods}
                          content="period"
                          isCollapsable
                          isCollapsed={collapsableSidebars.period}
                          handleCollapseItems={handleTriggerCollapsableSidebar}
                        />
                      )}

                      {!isEmpty(categoryRelatedOrigins) && (
                        <CategorySidebarItems
                          isSorted={false}
                          title="Origin"
                          items={categoryRelatedOrigins}
                          content="origin"
                          isCollapsable
                          isCollapsed={collapsableSidebars.origin}
                          handleCollapseItems={handleTriggerCollapsableSidebar}
                        />
                      )}

                      {!isEmpty(similarSearches) && (
                        <CategorySidebarItems
                          isSorted={false}
                          title={category.title ? `${categoryHeading} For Sale` : SIMILAR_SEARCHES}
                          renderHeader={() => (
                            <h2 className="home-sidebar--title-container--title">
                              {category.title ? `${categoryHeading} For Sale` : SIMILAR_SEARCHES}
                            </h2>
                          )}
                          items={similarSearches}
                          content="similarSearches"
                          isCollapsable
                          isCollapsed={collapsableSidebars.similarSearches}
                          handleCollapseItems={handleTriggerCollapsableSidebar}
                          linksAdditionalClassName="similar-searches-links-container"
                          renderItem={e => <h2 className="home-sidebar--row-item-value">{e}</h2>}
                        />
                      )}

                      {!isEmpty(visibleCategoryChildren) && !isTopCategory && renderSubcategoriesSidebar()}

                      {isSeoCategory && !itemsLoading && !loadingProcess && <SubscribeNewsletter />}

                      { !itemsLoading && !loadingProcess && <DirectoryAdsBanner /> }
                    </>
                  )}
                </Col>

                <Col xl={18} lg={24}>
                  <section className="category--info">
                    <div
                      className="category--info-banner"
                      style={{
                        backgroundImage: `url(${categoryBannerImageSrc})`,
                        backgroundPosition: "right center",
                        backgroundSize: "cover"
                      }}
                    >
                      <div className={`category--info-title${
                        width < 620 && categoryHeading.length > 50 ? " category--info-title__big-text" : ""
                      }`}>
                        <h1>{category.title ? categoryHeading : ""}</h1>
                        {location.pathname !== "/antiques" && !isAntiquesSoldPage && (
                          <div className="category--info-title__items-count">
                            {itemsCountTotalText}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="category--info-banner-search">
                      <CategoryPageSearch
                        isLoading={loadingProcess && categoryLoading}
                      />
                    </div>
                    {!!(otherQueries.title && data.itemsCount && !itemsLoading && !loadingProcess) && (
                      <div className="category--info-items-count">
                        Found
                        <span>{data.itemsCount}</span>
                        items for
                        <span>{decodeURIComponent(otherQueries.title)}</span>
                      </div>
                    )}
                    {!!category.description && page === 1 && (
                      <div className="category--info-description_open">
                        <ShowMore lines={2}>
                          {parse(category.description.toString())}
                        </ShowMore>
                      </div>
                    )}
                  </section>

                  <section className="category--items">
                    <div className={`category--items-header ${
                      isSeoCategory || isAntiquesSoldPage || location.pathname === "/antiques"
                        ? "category--items-header__one-column"
                        : ""
                    }`}>
                      {!isSeoCategory && !isAntiquesSoldPage && location.pathname !== "/antiques" && (
                        <div>
                          <SaveSearchButton
                            disabled={categoryLoading}
                            buttonLocation="category"
                            categoryHeading={category.title}
                            keywordSearch={category.title}
                            buttonLocationId={category.id}
                            showTooltip
                            handleClick={returnSubscribeToCategoryDataLayer}
                          />
                        </div>
                      )}
                      {!isAntiquesSoldPage && (
                        <div className={"category--filters-row"}>
                          {width < 1200 && (
                            <div className="category--filters-row--mobile-filter__container">
                              <div className="category--filters-row--mobile-filter">
                                <Select
                                  suffixIcon={<CombinedSortIcon />}
                                  data-testid="mobileSortBySelect"
                                  className="filter-box"
                                  placeholder={selectedOrderingOption?.label || "Sort By"}
                                  filterOption={false}
                                  notFoundContent={null}
                                  onClick={() => setIsSortOptionsDrawerOpen(!isSortOptionsDrawerOpen)}
                                />
                                <Select
                                  suffixIcon={<CombinedSortIcon />}
                                  data-testid="mobileBrowseToSelect"
                                  className="filter-box filter-box_center"
                                  placeholder={(selectedSubcategory && selectedSubcategory.label) || "Filter by"}
                                  filterOption={false}
                                  notFoundContent={null}
                                  onClick={() => setIsBrowseToDrawerOpened(!isBrowseToDrawerOpened)}
                                />
                              </div>
                              {renderFiltersTags()}
                            </div>
                          )}
                          <div className="category--filters-row--desktop-filter subcategory-dropdown">
                            <CategorySelect
                              categoriesList={
                                breadcrumbs && breadcrumbs.length
                                  ? modifyChildrenUrls(visibleCategoryChildren, breadcrumbs[0].url)
                                  : allCategories
                              }
                              categoryRelatedMaterials={categoryRelatedMaterials}
                              categoryRelatedPeriods={
                                !location.pathname.includes("/antiques")
                                  ? categoryRelatedPeriods
                                  : modifyChildrenUrls(allPeriods, "period")
                              }
                              categoryRelatedOrigins={categoryRelatedOrigins}
                              similarSearches={similarSearches}
                              similarSearchesTitle={category.title ? categoryHeadingWithItems : SIMILAR_SEARCHES}
                            />
                          </div>
                          <div className="category--filters-row--desktop-filter">
                            <DropDown
                              size="middle"
                              value={filter}
                              options={CATEGORY_SORTING_OPTIONS}
                              onChange={value => handleChangeSort(value)}
                              label="Sort by:"
                            />
                          </div>
                          <div className="category--filters-row--desktop-filter">
                            <DropDown
                              size="middle"
                              value={perPage}
                              options={DEFAULT_PAGE_SIZE_OPTIONS}
                              onChange={value => handleChangePageSize(value)}
                              className={
                                `category--filters-row--items-count ${isAntiquesSoldPage ? "antiques-sold" : ""}`
                              }
                              label="Items:"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="category--items-wrapper" ref={itemsListRef}>
                      <Row gutter={[30, 30]}>
                        {itemsLoading ? (
                          <ItemBlockSkeleton amount={40} />
                        ) : itemsCount ? items.map(item => (
                          <ItemBlock
                            key={item.id}
                            item={item}
                            className="category--item"
                            sm={12}
                            md={6}
                          />
                        )) : (
                          <div className="category--no-items-hint">
                            {
                              otherQueries.priceMin || otherQueries.priceMax || otherQueries.title
                                ? "Sorry, no exact matches found"
                                : "This category doesn't have items yet"
                            }
                          </div>
                        )}
                      </Row>
                    </div>

                    <Pagination
                      className="category--items-pagination"
                      total={itemsCount}
                      pageSize={perPage}
                      current={+page}
                      onChange={(p) => {
                        setQueryParams({ page: p === 1 ? null : p })
                      }}
                      hideOnSinglePage
                    />
                  </section>
                </Col>
              </Row>
            </>
          )}
        </div>

        {(topCategory.id === category.id && topCategory.bottomDescription && page === 1) && (
          <div className="category-page-bottom page-container ql-editor">
            {parse(topCategory.bottomDescription.toString())}
          </div>
        )}

        {(topCategory.id !== category.id && category.bottomDescription && page === 1) && (
          <div className="category-page-bottom page-container ql-editor">
            {parse(category.bottomDescription.toString())}
          </div>
        )}

        <section className="page-container">
          <Row>
            <Col md={0} sm={24} xs={24}>
              {isSeoCategory && location.pathname !== "/antiques" && (
                <React.Fragment>
                  <SubscribeNewsletter />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </section>
        {width < 1200 && (
          <>
            <BrowseToNavigationDrawer
              onClose={() => setIsBrowseToDrawerOpened(false)}
              isOpened={isBrowseToDrawerOpened}
              categoriesList={
                breadcrumbs && breadcrumbs.length
                  ? modifyChildrenUrls(visibleCategoryChildren, breadcrumbs[0].url)
                  : allCategories
              }
              categoryRelatedMaterials={categoryRelatedMaterials}
              categoryRelatedPeriods={
                !location.pathname.includes("/antiques")
                  ? categoryRelatedPeriods
                  : modifyChildrenUrls(allPeriods, "period")
              }
              categoryRelatedOrigins={categoryRelatedOrigins}
              similarSearches={similarSearches}
              similarSearchesTitle={category.title ? categoryHeadingWithItems : SIMILAR_SEARCHES}
            />
            <SortByDrawer
              onClose={() => setIsSortOptionsDrawerOpen(false)}
              isOpened={isSortOptionsDrawerOpen}
              sortOptions={CATEGORY_SORTING_OPTIONS}
            />
          </>
        )}
      </div>
    </>
  );
};

CategoryPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  periods: PropTypes.array.isRequired,
  categoryRelatedPeriods: PropTypes.array.isRequired,
  categoryRelatedOrigins: PropTypes.array.isRequired,
  categoryRelatedMaterials: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  openDrawer: PropTypes.func.isRequired,
  setDropdownKey: PropTypes.func.isRequired,
  allPeriods: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  getFavoriteItemsIds: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    allPeriods: state.periods.allPeriods,
    periods: getTopPeriods(state),
    allCategories: state.home.allCategories,
    categoryRelatedPeriods: state.categoryPage.categoryRelatedPeriods,
    categoryRelatedOrigins: state.categoryPage.categoryRelatedOrigins,
    categoryRelatedMaterials: state.categoryPage.categoryRelatedMaterials,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    openDrawer: openMobileDropdown,
    setDropdownKey: setMobileDropdownKey,
    getFavoriteItemsIds: getFavoriteItemsIdsRequest,
  }
)(React.memo(CategoryPage));
