import React, { useState, useCallback } from "react";
import { Input } from "antd";
import { debounce } from "lodash";

// hooks
import useQueryParams from "../../../hooks/useQueryParams";

const CategoryPageSearch = ({ isLoading }) => {
  const [{ title }, setQueryParams] = useQueryParams();

  const [searchKeyword, setSearchKeyword] = useState(
    title ? decodeURIComponent(title) : ""
  );

  const handleSearch = useCallback(
    debounce((value) => {
      setQueryParams({ title: encodeURIComponent(value) });
    }, 100),
    [setQueryParams]
  );

  const handleChange = useCallback(
    (value) => {
      setSearchKeyword(value);

      if (!value && title) {
        handleSearch("");
      }
    },
    [title]
  );

  return (
    <Input.Search
      size="large"
      enterButton
      placeholder="Search antiques in category"
      value={searchKeyword}
      onChange={(evt) => handleChange(evt.target.value)}
      onSearch={handleSearch}
      loading={isLoading}
    />
  );
};

export default CategoryPageSearch;
