import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// components
import BreadcrumbSeparator from "../shared/BreadcrumbSeparator";

// helpers
import { returnBreadcrumbScript } from "../../helpers";

const CategoryBreadcrumbs = ({ currentCategory, breadcrumbs, breadcrumbsError }) => {
  const { pathname } = useLocation();
  const loading = typeof breadcrumbs === "undefined" && typeof breadcrumbsError === "undefined";
  const soldItemsArchivePathname = "/antiques/sold";
  const allAntiquesPathname = "/antiques";

  const breadcrumbsToRender = useMemo(() => breadcrumbs, [breadcrumbs]);

  const isCategoryInBreadcrumbs = useMemo(() => {
    return !!(breadcrumbsToRender || []).find(c => c.url.includes(currentCategory.url));
  }, [breadcrumbsToRender, currentCategory]);

  const breadcrumbsForSchemaScript = [{ title: "Home", url: "" }];

  if (pathname === soldItemsArchivePathname) {
    breadcrumbsForSchemaScript.push({ title: "Sold", url: soldItemsArchivePathname });
  }

  if (breadcrumbsToRender && breadcrumbsToRender.length) {
    breadcrumbsForSchemaScript.push(...breadcrumbsToRender);
  }

  if (
    !isCategoryInBreadcrumbs &&
    pathname !== allAntiquesPathname &&
    pathname !== soldItemsArchivePathname &&
    currentCategory.title
  ) {
    if (breadcrumbsToRender) {
      breadcrumbsToRender.push({ title: `${currentCategory.title}`, url: `${currentCategory.url}` });
    }

    breadcrumbsForSchemaScript.push({ title: `${currentCategory.title}`, url: `${currentCategory.url}` });
  }

  // Render
  if (loading) {
    return null;
  }

  return (
    <>
      <Helmet>
        {breadcrumbsForSchemaScript.length && (
          <script type="application/ld+json">{returnBreadcrumbScript(breadcrumbsForSchemaScript)}</script>
        )}
      </Helmet>
      <Breadcrumb separator={<BreadcrumbSeparator />}>
        <Breadcrumb.Item className="ant-breadcrumb--item">
          <Link to="/">
            <p>Home</p>
          </Link>
        </Breadcrumb.Item>

        {pathname === allAntiquesPathname && (
          <Breadcrumb.Item className="ant-breadcrumb--item">
            <p>latest antiques</p>
          </Breadcrumb.Item>
        )}

        {pathname === soldItemsArchivePathname && (
          <Breadcrumb.Item className="ant-breadcrumb--item">
            <p>Sold</p>
          </Breadcrumb.Item>
        )}

        {breadcrumbsToRender && breadcrumbsToRender.map((item, index) => (
          <Breadcrumb.Item key={item.url} className="ant-breadcrumb--item">
            {
              index !== breadcrumbsToRender.length - 1
                ? (<Link to={`/${item.url}`}>
                  <p>{item.title}</p>
                </Link>)
                : (<p>{item.title}</p>)
            }
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </>
  );
};

CategoryBreadcrumbs.propTypes = {
  currentCategory: PropTypes.object,
  breadcrumbs: PropTypes.array,
  breadcrumbsError: PropTypes.object
};

export default React.memo(CategoryBreadcrumbs);
