import React, { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Select } from "antd";

// assets
import CombinedSortIcon from "../../Icons/CombinedSortIcon";
import ChevronIcon from "../../Icons/ChevronIcon";


const CategorySelect = ({
  categoriesList = [],
  categoryRelatedMaterials = [],
  categoryRelatedOrigins = [],
  categoryRelatedPeriods = [],
  similarSearches = [],
  similarSearchesTitle,
}) => {
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  const history = useHistory();
  const { pathname } = useLocation();

  const mainNavigation = [
    { name: "Materials", key: "materials", navigationLinksList: categoryRelatedMaterials },
    { name: "Periods", key: "periods", navigationLinksList: categoryRelatedPeriods },
    { name: "Origins", key: "origins", navigationLinksList: categoryRelatedOrigins },
    { name: similarSearchesTitle, key: "similarSearches", navigationLinksList: similarSearches },
  ].filter(({ navigationLinksList }) => navigationLinksList?.length);
  const isTopLevelOptions = mainNavigation?.length && pathname !== "/antiques";

  const renderOption = (item) => (
    <Select.Option key={item.url}>
      <div
        className="drop-down--component--wrapper"
        onClick={() => history.push({ pathname: `/${item.url}` })}
      >
        <span className="drop-down--component--wrapper--title">{item.title}</span>
        <span className="drop-down--component--wrapper--items-count">{item.itemsCount}</span>
      </div>
    </Select.Option>
  );

  return (
    <div className="drop-down">
      <div className="drop-down-component--label">Filter by:</div>
      <Select
        suffixIcon={<CombinedSortIcon />}
        className="drop-down-component"
        popupClassName="drop-down-component--category-select"
        placeholder="Choose category"
        filterOption={false}
        notFoundContent={null}
        onDropdownVisibleChange={() => setSelectedSubcategories([])}
      >
        {isTopLevelOptions ? mainNavigation.map(({ name, key, navigationLinksList }) => {
          const isSelected = selectedSubcategories.some((item) => item === key);

          return (
            <Fragment key={name}>
              <Select.Option key={key} className="drop-down-component--category-select--main-option">
                <div
                  className={`mobile-filter-drawer__main-nav-link${isSelected ? " mobile-filter-drawer__selected" : ""}`}
                  onClick={(evt) => {
                    evt.stopPropagation();

                    setSelectedSubcategories(
                      isSelected
                        ? selectedSubcategories.filter((item) => item !== key)
                        : selectedSubcategories.concat(key)
                    );
                  }}
                >
                  <span className="mobile-filter-drawer__main-nav-label">{name}</span>
                  <ChevronIcon
                    className="mobile-filter-drawer__main-nav-icon"
                    direction={isSelected ? "top" : "right"}
                  />
                </div>
              </Select.Option>
              {isSelected && navigationLinksList.map(renderOption)}
            </Fragment>
          );
        }) : categoriesList?.map(renderOption)}
      </Select>
    </div>
  );
}

CategorySelect.propTypes = {
  categoriesList: PropTypes.array,
  categoryRelatedMaterials: PropTypes.array,
  categoryRelatedOrigins: PropTypes.array,
  categoryRelatedPeriods: PropTypes.array,
  similarSearches: PropTypes.array,
  similarSearchesTitle: PropTypes.string,
};

export default CategorySelect;
