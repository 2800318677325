import React from "react";
import { Button } from "antd";

// assets
import "./DirectoryAdsBanner.scss";


function DirectoryAdsBanner() {
  return (
    <div className="directory-ads-banner">
      <img
        src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/business/uk-map.svg`}
        alt="uk-map"
        className="directory-ads-banner--image"
      />
      <h3 className="directory-ads-banner--title">
        Find Dealers, Antiques Fairs & More near You
      </h3>
      <Button href="/directory" className="directory-ads-banner--link">
        Go to directory
      </Button>
    </div>
  );
}

export default DirectoryAdsBanner;
